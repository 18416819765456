<template>
    <div>
        <a-card 
            v-for="config in paramNumerico" 
            :key="config.tipoconfiguracion_id" 
            size="small" 
            style="width: 100%"
        >
            <span slot="title" style="font-weight: 700;">
                PARÁMETRO DE {{ config.nombre }}
            </span>
            <a-list item-layout="horizontal" :data-source="paramNumerico">
                <a-list-item 
                    v-for="item in config.configuraciones" 
                    :key="item.configuracion_id"
                    >
                    <a-col flex="1 1 850px" :span="24">
                        <a-list-item-meta>
                            <span slot="title" style="font-size:17px; font-weight: 700;">
                                {{ item.titulo }}
                            </span>
                            <span slot="description" style="font-size:14px;">
                                {{ item.descripcion }} 
                            </span>
                        </a-list-item-meta>
                    </a-col>
                    <a-col flex="1 1 50px"></a-col>
                    <a-col flex="1 1 200px" :span="4">
                        <a-select 
                            v-model="item.data_numerico"
                            size="large"
                            style="width: 50%"
                            @change="(value) => onSelectChange(item.configuracion_id, value)"
                            >
                            <a-select-option v-for="num in 100" :key="num" :value="num">
                                {{ num }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-list-item>
            </a-list>
        </a-card>

        <div v-if="spinnerloading" class="col-md-12" :style="spinnerStyle">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center>
                    <button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="cancelLoading">
                        <i class="fa fa-stop"></i> Cerrar Ventana de Espera
                    </button>
                </center>
            </div>
        </div>
    </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
import moment from 'moment'
import 'moment/locale/es'
import * as utils from '@/utils/utils'
import { mapGetters, mapActions } from 'vuex'

export default {
    data () {
        return {
            dateFormat: 'YYYY-MM-DD',
            moment,
            locale,
            utils,
            spinnerloading: false
        }
    }, 

    computed: {
        ...mapGetters('auth', ['user']),
        ...mapGetters('configuracion', ['paramNumerico'])
    },

    mounted () {
        this.getParametroSistema()
    },

    methods: {
        ...mapActions('configuracion', ['getParametroSistema', 'updateConfigNumerico']),

        onSelectChange (configuracionID, value) {
            this.updateConfigNumerico({ configuracionID, value })
            .then(response => {
                this.showNotification('success', 'Mensaje:', response.data.message)
            })
            .catch(error => {
                this.showNotification('error', 'Error al enviar la Información', this.showErrors(error.response.data))
            })
        },

        cancelLoading () {
            this.spinnerloading = false
        },

        showNotification (type, message, description) {
            utils.openNotificationWithIcon(type, message, description, 'topRight')
        },

        showErrors (errors) {
            return utils.showErrors(errors)
        },

        spinnerStyle () {
            return {
                position: 'fixed', 
                top: 0, 
                right: 0, 
                bottom: 0, 
                left: 0, 
                background: 'rgba(0, 0, 0, 0.5)', 
                zIndex: 1055
            }
        }
    }
}
</script>