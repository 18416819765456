<template>
    <div>
        <a-card size="small" style="width: 100%" v-for="(value, llave) in paramEnum" :key="llave.tipoconfiguracion_id">
            <span slot="title" style="font-weight: 700;">
                CONFIGURACION DE {{ value.nombre }}
            </span>
            <a-list item-layout="horizontal" :data-source="paramEnum">
                <a-list-item v-for="(item, index) in value.configuraciones" :key="item.configuracion_id">
                    <a-col flex="1 1 850px" :span="24">
                    <a-list-item-meta>
                        <span slot="title" style="font-size:14px;">
                            <span style="font-weight: 700;">{{ index + 1 }}.</span> {{ item.titulo }} {{ item.descripcion }}
                        </span>
                        <span slot="description" style="font-size:14px;">
                        </span>
                    </a-list-item-meta>
                    </a-col>
                    <a-col flex="1 1 200px" :span="4">
                        <a-col :span="12">
                            <a-tag color="green" v-if="item.data_enum === 'ACTIVADO'">
                                Activado
                            </a-tag>
                            <a-tag color="red" v-if="item.data_enum === 'DESACTIVADO'">
                                Desactivado
                            </a-tag>
                        </a-col>

                        <a-col :span="12">
                            <a-switch
                                size="large"
                                :checked="item.data_enum === 'ACTIVADO'" 
                                @change="(checked) => onSwitchDataEnum(item.configuracion_id, checked)" 
                            />
                        </a-col>
                    </a-col>
                </a-list-item>
            </a-list>
        </a-card>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>
    
<script>
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
    import * as utils from '@/utils/utils'
    import { mapGetters, mapActions } from 'vuex'
    import router from '@/config/router'

    export default {

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                utils,
                spinnerloading: false,
                lista: [
                    {
                        title: 'Reporte de ventas.',
                        description: 'Información de ventas realizadas.',
                        button: 'reporte-venta'
                    }
                ],
                buttonKey: 10
            }
        }, 
            
        mounted () {
            this.getParametroSistema()
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['paramEnum'])
        },
    
        methods: {
            ...mapActions('configuracion', ['getParametroSistema', 'updateConfig']),

            onSwitchDataEnum (configuracionID, checked) {
                const newState = checked ? 'ACTIVADO' : 'DESACTIVADO'
                this.updateConfig({ configuracionID, newState })
                .then(response => {
                    utils.openNotificationWithIcon('success', 'Mensaje:', response.data.message, 'topRight')
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            filterOption (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            }
        }
    }
</script>