<template>
	<div>
		<a-layout-footer :style="{ textAlign: 'center' }">
            <a href="https://efectivo.io" style="color:var(--element); font-weight:700;">efectivo.io</a> 
			Derechos Reservados. (+591) 67267432
		</a-layout-footer>
	</div>
</template>

<script>
	export default {
		name: 'Footer'
	}
</script>
