<template>
	<div>
		<a-layout-sider
			v-model="collapsed"
			:trigger="null"
			collapsible
			:collapsed-width="0"
			v-bind:style="sidebarStyle"
			:width="160"
			@breakpoint="handleBreakpoint"
			@click="handleSidebarClick"
			>

			<div class="sidebar-container">
				<div class="sidebar-top">
					<center>
						<a href="/dashboard">
							<a-avatar loading="lazy" :size="100" :src="(empresa.imagen) ? empresa.imagen : imagenLogo"/>
						</a>
						<br><br>
						<span style="font-size:14px; color:var(--element); font-weight: 700;">{{ user.usuarioIP }}</span>
						<span style="font-size:11px; color:var(--element); font-weight: 700;"><center>{{ user.nombreAlmacen }}</center></span>
						<span style="font-size:12px; color:var(--element); font-weight: 700;"><center>{{ user.usuario }}</center></span>
					</center>

					<hr>

					<center>
						<a-button-group>
							<a-button
								size="small"
								block
								@click="openMenu('escritorio')" 
								class="v-step-1"
								>
								<span style="font-size: 15px">Escritorio</span>
							</a-button>

							<a-button 
								size="small"
								block
								@click="openVentana"
								>
								<a-icon type="plus" :style="{ color: 'var(--element)' }"/>
							</a-button>
						</a-button-group>
					</center>

					<center>
						<a-button 
							type="primary"
							size="small"
							block 
							@click="openDrawerMenu" 
							> 
							<span style="font-size: 15px">Nueva Transaccion</span>
						</a-button>
					</center>
				</div>

				<div class="sidebar-middle">
					<a-menu
						mode="inline"
						:open-keys="openKeys"
						style="height: 100%"
						@openChange="onOpenChange"
						>
						<a-sub-menu key="sub1" v-if="$can('transaccion.menu.consultar.venta') || $can('estimacion.menu.consultar.cotizacion') || $can('conciliacion.menu.consultar.arqueo.de.caja')">
							<span slot="title">
								<a-icon type="import" :style="{ fontSize: '17px', color: 'var(--element)' }"/>
								<span style="font-size: 17px">Ingresos</span>
							</span>
							<a-menu-item :key="1" @click="openMenu('consultarsalida')" v-if="$can('transaccion.menu.consultar.venta')">
								Ventas
							</a-menu-item>
							<a-menu-item :key="2" @click="openMenu('cotizacion')" v-if="$can('estimacion.menu.consultar.cotizacion')">
								Cotizaciones
							</a-menu-item>
							<a-menu-item :key="3" @click="openMenu('consultacaja')" v-if="$can('conciliacion.menu.consultar.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
								Arqueo Caja
							</a-menu-item>
							<a-menu-item :key="4" @click="openMenu('ingresocaja')" v-if="$can('conciliacion.menu.consultar.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
								Ingreso a Caja
							</a-menu-item>
						</a-sub-menu>

						<a-sub-menu key="sub2" v-if="$can('conciliacion.menu.consultar.arqueo.de.caja') || $can('adquisicion.menu.consultar.compra')">
							<span slot="title">
								<a-icon type="export" :style="{ fontSize: '17px', color: 'var(--element)' }"/>
								<span style="font-size: 17px">Gastos</span>
							</span>
							<a-menu-item :key="5" @click="openMenu('egresocaja')" v-if="$can('conciliacion.menu.consultar.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
								Gastos Caja
							</a-menu-item>
							<a-menu-item :key="10" @click="openMenu('consultacompra')" v-if="$can('adquisicion.menu.consultar.compra')">
								Compras
							</a-menu-item>
						</a-sub-menu>

						<a-sub-menu key="sub3" v-if="$can('registro.menu.producto') || $can('existencia.menu.inventario') || $can('desplazamiento.menu.consultar.traspaso') || $can('correccion.menu.consultar.ajuste') || $can('agencia.menu.sucursal') || $can('sistema.gestion.de.categoria')">
							<span slot="title">
								<a-icon type="container" :style="{ fontSize: '17px', color: 'var(--element)' }" /> 
								<span style="font-size: 17px">Inventarios</span>
							</span>
							<a-menu-item :key="11" @click="openMenu('catalogo')" v-if="$can('registro.menu.producto')">
								Productos
							</a-menu-item>
							<a-menu-item :key="12" @click="openMenu('inventario')" v-if="$can('existencia.menu.inventario')">
								Inventarios
							</a-menu-item>
							<a-menu-item :key="13" @click="openMenu('consultatranspaso')" v-if="$can('desplazamiento.menu.consultar.traspaso')">
								Transpasos
							</a-menu-item>
							<a-menu-item :key="14" @click="openMenu('consultaajusteinventarioentrada')" v-if="$can('correccion.menu.consultar.ajuste')">
								Ajuste Entrada
							</a-menu-item>
							<a-menu-item :key="15" @click="openMenu('consultaajusteinventariosalida')" v-if="$can('correccion.menu.consultar.ajuste')">
								Ajuste Salida
							</a-menu-item>
							<a-menu-item :key="16" @click="openMenu('sucursal')" v-if="$can('agencia.menu.sucursal')">
								Sucursales
							</a-menu-item>
							<a-menu-item :key="17" @click="openMenu('tipodeprecioventa')" v-if="$can('sistema.gestion.de.categoria')">
								Lista Precios
							</a-menu-item>
						</a-sub-menu>

						<a-sub-menu key="sub4" v-if="$can('abastecedor.menu.proveedor') || $can('consumidor.menu.cliente')">
							<span slot="title">
								<a-icon type="user" :style="{ fontSize: '17px', color: 'var(--element)' }"/> 
								<span style="font-size: 17px">Contactos</span>
							</span>
							<a-menu-item :key="18" @click="openMenu('proveedor')" v-if="$can('abastecedor.menu.proveedor')">
								Proveedores
							</a-menu-item>
							<a-menu-item :key="19" @click="openMenu('cliente')" v-if="$can('consumidor.menu.cliente')">
								Clientes
							</a-menu-item>
						</a-sub-menu>

						<a-sub-menu key="sub5" v-if="$can('informe.menu.reporte')">
							<span slot="title">
								<a-icon type="printer" :style="{ fontSize: '17px', color: 'var(--element)' }"/>
								<span style="font-size: 17px">Informes</span>
							</span>
							<a-menu-item :key="21" @click="openMenu('centroreporte')" v-if="$can('informe.menu.reporte')">
								Reportes
							</a-menu-item>
						</a-sub-menu>

						<a-sub-menu key="sub6" v-if="$can('sistema.gestion.de.categoria') || $can('sistema.gestion.de.empresa') || $can('manager.menu.usuario') || $can('funcion.submenu.roles')">
							<span slot="title">
								<a-icon type="setting" :style="{ fontSize: '17px', color: 'var(--element)' }"/>
								<span style="font-size: 17px">Parámetros</span>
							</span>
							<a-menu-item :key="23" @click="openMenu('parametros')" v-if="$can('sistema.gestion.de.categoria')">
								Configuración
							</a-menu-item>
							<a-menu-item :key="25" @click="openMenu('empresa')" v-if="$can('sistema.gestion.de.empresa')">
								Empresa
							</a-menu-item>
							<a-menu-item :key="29" @click="openMenu('usuario')" v-if="$can('manager.menu.usuario')">
								Usuarios
							</a-menu-item>
							<a-menu-item :key="30" @click="openMenu('roles')" v-if="$can('funcion.submenu.roles')">
								Roles
							</a-menu-item>
						</a-sub-menu>
					</a-menu>
				</div>

				<div class="sidebar-bottom">
					<center>
						<a-button
							v-if="$can('existencia.administrar.importar.inventario.excel')" 
							size="small"
							type="primary"
							block
							@click="openImportacionMasiva()"
							>
							Importacion Masiva
						</a-button>
					</center>
					<center>
						<span class="img-thumbnail" v-if="this.config.enablemeasuredspeed === 'ACTIVADO'">
							<a-icon type="wifi" :style="{ color: 'var(--element)', fontWeight: '700' }"/> 
							<span class="color-titulo-velocidad">{{ velocidad_a }}<br></span>
							<span v-if="this.status_velocidad == 1" style="color:#dc1602; font-weight:700;"> Conexión Critica</span>
							<span v-if="this.status_velocidad == 2" style="color:#d67b00; font-weight:700;"> Conexión Lenta</span>
							<span v-if="this.status_velocidad == 3" style="color:#0265dc; font-weight:700;"> Conexión Moderada</span>
							<span v-if="this.status_velocidad == 4" style="color:#47b703; font-weight:700;"> Conexión Estable</span>
						</span>
					</center>
				</div>
			</div>
		</a-layout-sider>

		<!--IMPORTACION MASIVA-->
		<a-modal
            v-model="modalimportacion"
            :title="false"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="380"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <a-row type="flex" :gutter="[2,2]">
					<a-col flex="1 0 100%">
						<label class="control-label">SUCURSALES</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onSearchSucursal"  style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
					</a-col>

					<a-col flex="1 0 100%">
						<File
                            titulo="Agregar Archivo de Importacion Excel"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @uploadFile="successFileImportacion"
                        />
					</a-col>

					<a-col flex="1 0 100%">
						<a-button 
							@click.once="guardarImportacionExcel()"
							:key="buttonKeyImportacionExcel"
							type="primary"
							size="large"
							block
							>
							<a-icon type="upload" :style="{ fontSize: '24px' }"/> Importar Archivo EXCEL
						</a-button>
					</a-col>

					<br>

					<a-col flex="1 0 100%">
						<a-button 
							@click="descargaPlantillaExcel()"
							block
							>
							<a-icon type="file-excel" :style="{ color: '#009717', fontSize: '20px' }"/> Descarga la plantilla en Excel
						</a-button>
					</a-col>
				</a-row>
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalimportacion = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>

		<a-drawer
            title="Realizar Nueva Transaccion"
            placement="left"
            :closable="false"
            :mask="true"
            :maskClosable="true"
            :destroyOnClose="true"
            :visible="visibleDrawer"
            :zIndex="1030"
            :width="240"
            @close="onClose"
            >
            <Menu 
				@successDrawer="successDrawer"
			/>
        </a-drawer>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'
	import Swal from 'sweetalert2'
	import Cookies from 'js-cookie'
	import File from '../components/File.vue'
	import Menu from './Menu.vue'
	import {
        ALMACEN_SISTEMA_KEY_ID
    } from '@/utils/constants'

	export default {

		props: [
			'velocidad_a', 
			'status_velocidad',
			'collapsed', 
			'sidebarStyle'
		],

		components: {
			File,
			Menu
		},

		data () {
			return {
				imagenLogo: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/icono.png' : '/static/assets/images/icono.png',
				rootSubmenuKeys: ['sub0', 'sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9', 'sub10', 'sub11', 'sub12', 'sub13', 'sub14', 'sub15', 'sub16', 'sub17', 'sub18', 'sub19', 'sub20'],
      			openKeys: ['sub1'],
				menus: [
					{
						key: 'sub12', 
						icon: 'bar-chart', 
						title: 'ESTADISTICA', 
						submenus: [
							{ subkey: 1, link: 'catalogo', subtitle: 'Tablero General' }
						] 
					}
				],
				consulta: {
                    almacen_id: 'Seleccionar',
					usuario_id: 'Seleccionar',
                    proveedor_id: 'Seleccionar',
                    nombreAlmacen: '',
                    fecha_inicio: '',
                    fecha_final: '',
                    keyword: ''
                },
				buttonKeyLogout: 10,
				buttonKeyPlantillaImportacionExcel: 20,
				buttonKeyImportacionExcel: 30,
				modalimportacion: false,
				spinnerloading: false,
				file: {
                    importacionExcel: []
                },
				visibleDrawer: false
			}
		},

		computed: {
			...mapGetters('auth', ['user']),
			...mapGetters('empresa', ['empresa']),
			...mapGetters('almacen', ['almacenes']),
            ...mapGetters('inventario', ['plantillaImportacion']),
			...mapGetters('configuracion', ['config'])
		},

		methods: {
			...mapActions('auth', ['logout']),
			...mapActions('almacen', ['getAlmacenListado']),
			...mapActions('inventario', ['importacionExcel', 'plantillaImportacionExcel']),
			...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

			onClose () {
                this.visibleDrawer = false
            },

			openDrawerMenu () {
                this.visibleDrawer = true
            },

			successDrawer () {
				setTimeout(() => {
					this.visibleDrawer = false
				}, 500)
			},

			openVentana () {
				const url = `${window.location.origin}/dashboard`
				window.open(url, '_blank')
			},

			handleBreakpoint (broken) {
				this.$emit('breakpoint', broken)
			},

			handleMenuClick () {
				this.$emit('menuClick')
			},

			handleSidebarClick () {
				this.$emit('sidebarClick')
			},

			onOpenChange (openKeys) {
				const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
				if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
					this.openKeys = openKeys
				} else {
					this.openKeys = latestOpenKey ? [latestOpenKey] : []
				}
    		},

			openMenu (name) {
				router.push({ name: name })
			},

			openMenuQuery (name, query) {
				this.$router.push({
					name: name,
					params: { ventanaID: query }
				})
			},

			openMenuQueryCotizacion (name, query) {
				this.$router.push({
					name: name,
					params: { ventanacotizacionID: query }
				})
			},

			irPagina (ruta) {
				this.$router.push({ path: ruta })
			},

			mensajeConstruccion () {
				alert('Esta funcionalidad esta en construccion...')
			},

			cerrarSesion () {
				this.logout()
				this.buttonKeyLogout++
			},

			onSearchSucursal (value) {
                this.consulta.almacen_id = value.key
            },

			openImportacionMasiva () {
				this.modalimportacion = true
			},

            descargaPlantillaExcel () {
                Swal.fire({
                        title: 'Desea Descargar la Plantilla para la Importacion?',
                        text: 'Tome en cuenta que esta plantilla sirve exclusivamente para la importacion de inventario al Sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
						this.plantillaImportacionExcel()
                        .then(response => {
                            if (response.status === 200) {
                                window.location.href = this.plantillaImportacion.link
                                this.spinnerloading = false
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyPlantillaImportacionExcel++
            },

            successFileImportacion (value) {
                this.file.importacionExcel = value
            },

			guardarImportacionExcel () {
				Swal.fire({
                    title: 'Desea Importar Datos al Sistema ?',
                    text: 'Esta opción guardara todos los datos existentes en el sistema y los reemplazara por la nueva Importación, Atención!!! Esta opción se recomienda utilizar la primera vez del uso del sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
						this.importacionExcel({ ...this.file, ...this.consulta, ...this.user })
                        .then(response => {
                            if (response.status === 201) {
                                this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
								this.getListaScrollMasivoProducto(this.consulta)
								this.getListaScrollMasivoProductoStock(this.consulta)
								setTimeout(function () { 
									this.modalimportacion = false       
									this.spinnerloading = false
									utils.openNotificationWithIcon('success', 'Mensaje : ', response.data.message, 'topRight')
								}.bind(this), 5000)
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyImportacionExcel++
			}
		},

		mounted () {
			this.getAlmacenListado()
			this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
		}
	}
</script>

<style>

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #ffffff;
    transition: all 0.2s;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-top {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.sidebar-middle {
  flex-grow: 1;
  overflow: auto;
}
.sidebar-bottom {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.ant-btn {
    padding: 0 7px;
}

.ant-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4) !important;
    user-select: text;
}
</style>