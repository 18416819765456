<template>
    <a-card size="small" style="width: 100%">
        <a-row type="flex" justify="center" :gutter="[10, 15]">
            <a-col :span="24">
                <a-divider orientation="left">
                    <span style="color:black; font-size:18px;">Configura los datos de tu empresa</span>&nbsp;
                    <span style="color:#9C9C9C; font-size:12px;">Datos para los registros de documentos</span>
                </a-divider>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <FileImage
                    titulo="Agregar Logo Principal 450 x 450"
                    @uploadImage="successLogoPrincipal"
                />
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Color de Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <ColorPicker
                    :color="dataEmpresa.color"
                    @successColor="successColor"
                />
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Nombre de la Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.nombre" placeholder="Nombre de la Empresa" onfocus="myFunction(this)" />
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Nombre del Propietario: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.propietario" placeholder="Nombre del Propietario" onfocus="myFunction(this)"/>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Dirección: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.direccion" placeholder="Dirección de la Empresa" onfocus="myFunction(this)"/>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Teléfono Fijo: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.telefono" placeholder="Teléfono de la Empresa" onfocus="myFunction(this)"/>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Teléfono Celular: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.celular" placeholder="Teléfono Celular de la Empresa" onfocus="myFunction(this)"/>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <label>Ciudad: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                <a-input size="large" v-model="dataEmpresa.ciudad" placeholder="Ciudad de la Empresa" onfocus="myFunction(this)"/>
            </a-col>

            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" :xl-offset="1" :lg-offset="1" :md-offset="2">
                <a-button  
                    @click.once="editarEmpresa()" 
                    :key="buttonKeyActualizarEmpresa" 
                    type="primary" 
                    :loading="loading"
                    block
                    size="large"
                    >
                    <a-icon type="edit" /> Actualizar los datos de Empresa
                </a-button>    
            </a-col>
        
            <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
                <div style="margin-top: 250px;">
                    <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                    <br><br>
                    <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
                </div>
            </div>

        </a-row>
    </a-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import FileImage from '../../../components/FileImage.vue'
import wysiwyg from 'vue-wysiwyg'
import ColorPicker from '../../../components/ColorPicker'
import * as utils from '@/utils/utils'

Vue.use(VueFileAgent)
Vue.use(wysiwyg, {
    hideModules: { 
        image: true,
        code: true,
        removeFormat: true 
    }
})

export default {

    components: {
        FileImage,
        ColorPicker
    },

    data () {
        return {
            utils,
            buttonKeyActualizarEmpresa: 10,
            loading: false,
            spinnerloading: false,
            dataEmpresa: {}
        }
    },

    mounted () {
        setTimeout(() => {
            this.dataEmpresa = { ...this.empresa }
        }, 2000)
    },

    computed: {
        ...mapGetters('auth', ['user']),
        ...mapGetters('empresa', ['empresa'])
    },

    methods: {
        ...mapActions('empresa', ['showEmpresa', 'updateEmpresa']),

        successLogoPrincipal (value) {
            this.dataEmpresa.file = value
        },

        successLogoHorizontal (value) {
            this.dataEmpresa.file_horizontal = value
        },

        successColor (value) {
            this.dataEmpresa.color = value
        },

        editarEmpresa () {
            this.spinnerloading = true
			this.updateEmpresa(this.dataEmpresa)
            .then(response => {
                if (response.status === 201) {
                    this.showEmpresa()
				    utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                    this.spinnerloading = false
                }
			})
            .catch(error => {
				this.loading = false
				utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
			})
            this.buttonKeyActualizarEmpresa++
        }
    }
}
</script>