<template>
    <div class="product-options">
		<center><h3>Tienes dos opciones para agregar productos</h3></center>
		
        <a-row :gutter="[10, 10]">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-card size="small" style="width: 100%">
                    <a-card-meta>
                        <span slot="title" style="font-weight: 700;">
                            Opción 1: Importación masiva mediante un archivo Excel.
                        </span>
                        <span slot="description">
                            <a-row type="flex" :gutter="[20,10]">
                                <a-col flex="1 0 100%">
                                    <label class="control-label">Sucursales</label>
                                    <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onSearchSucursal"  style="width: 100%">
                                        <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                                    </a-select>
                                </a-col>

                                <a-col flex="1 0 100%">
                                    <File
                                        titulo="Agregar Archivo de Importacion Excel"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        @uploadFile="successFileImportacion"
                                    />
                                </a-col>

                                <a-col flex="1 0 100%">
                                    <a-button 
                                        @click.once="guardarImportacionExcel()"
                                        :key="buttonKeyImportacionExcel"
                                        type="primary"
                                        size="large"
                                        block
                                        >
                                        <a-icon type="upload" :style="{ fontSize: '24px' }"/> Importar Archivo EXCEL
                                    </a-button>
                                </a-col>

                                <br>

                                <a-col flex="1 0 100%">
                                    <a-button 
                                        @click="descargaPlantillaExcel()"
                                        block
                                        size="large"
                                        >
                                        <a-icon type="file-excel" :style="{ color: '#009717', fontSize: '20px' }"/> Descarga la plantilla en Excel
                                    </a-button>
                                </a-col>
                            </a-row>   
                            <br>
                            <span>También puedes utilizar la importación masiva mediante un archivo Excel.</span><br><br>
                            <a-alert 
                                message="Notas importantes al usar la plantilla de Excel:" 
                                type="warning"
                                banner
                                closable
                                >
                                <p slot="description">
                                    <ul>
                                    <li><span class="note-title">Proveedor:</span> No debe estar vacío (el nombre del proveedor).</li>
                                    <li><span class="note-title">Código:</span> No debe estar vacío, debe contener solo números (código de barras).</li>
                                    <li><span class="note-title">Nombre:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
                                    <li><span class="note-title">Medida:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
                                    <li><span class="note-title">Unidad:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
                                    <li><span class="note-title">Categoría:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
                                    <li><span class="note-title">Marca:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
                                    <li><span class="note-title">Cantidad:</span> No debe estar vacío, solo números y decimales.</li>
                                    <li><span class="note-title">P.Compra:</span> No debe estar vacío, solo números y decimales.</li>
                                    <li><span class="note-title">P.Venta:</span> No debe estar vacío, solo números y decimales.</li>
                                    </ul>
                                    <strong>Nota:</strong> En el archivo de Excel, asegúrate de que todo el texto esté sin formato
                                </p>
                            </a-alert>
                        </span>
                    </a-card-meta>
                </a-card>        
            </a-col>

            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-card size="small" style="width: 100%">
                    <a-card-meta>
                        <span slot="title" style="font-weight: 700;">
                            Opción 2: Crear nuevo producto manualmente.
                        </span>
                        <span slot="description">
                            <ComponenteProducto
                                :tipoproducto="'agregar'"
                                :dataproducto="[]"
                                :almacenid="parseInt(consulta.almacen_id)"
                                :proveedorid="'1'"
                                :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
                                @successProducto="dataSuccessProducto"
                            />
                        </span>
                    </a-card-meta>
                </a-card>     
            </a-col>
        </a-row>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as utils from '@/utils/utils'
import router from '@/config/router'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import File from '../../../components/File.vue'
import {
    ALMACEN_SISTEMA_KEY_ID
} from '@/utils/constants'
import ComponenteProducto from '../../../pages/Inventario/Components/ComponenteProducto.vue'

export default {

    components: {
        ComponenteProducto,
		File
	},

    data () {
        return {
			imageMasivo: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/ImportacionMasiva.gif' : '/static/assets/images/sistema/ImportacionMasiva.gif',
			imageProducto: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProducto.gif' : '/static/assets/images/sistema/NuevoProducto.gif',
			imageProductoBase: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProductoBase.gif' : '/static/assets/images/sistema/NuevoProductoBase.gif',
			imageProductoHeader: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProductoHeader.gif' : '/static/assets/images/sistema/NuevoProductoHeader.gif',
            consulta: {
                almacen_id: 'Seleccionar',
				usuario_id: 'Seleccionar',
                proveedor_id: 'Seleccionar',
                nombreAlmacen: '',
                fecha_inicio: '',
                fecha_final: '',
                keyword: ''
            },
            buttonKeyPlantillaImportacionExcel: 20,
			buttonKeyImportacionExcel: 30,
			modalimportacion: false,
			spinnerloading: false,
			file: {
                importacionExcel: []
            }
        }
    },

    mounted () {
		this.getAlmacenListado()
		this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
	},

    computed: {
		...mapGetters('auth', ['user']),
		...mapGetters('empresa', ['empresa']),
		...mapGetters('almacen', ['almacenes']),
        ...mapGetters('inventario', ['plantillaImportacion']),
		...mapGetters('configuracion', ['config'])
	},

	methods: {
        ...mapActions('auth', ['logout']),
		...mapActions('almacen', ['getAlmacenListado']),
		...mapActions('inventario', ['importacionExcel', 'plantillaImportacionExcel']),
		...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

		openMenu (name) {
			router.push({ name: name })
		},

        onSearchSucursal (value) {
            this.consulta.almacen_id = value.key
        },

		openImportacionMasiva () {
			this.modalimportacion = true
		},

        descargaPlantillaExcel () {
            Swal.fire({
                    title: 'Desea Descargar la Plantilla para la Importacion?',
                    text: 'Tome en cuenta que esta plantilla sirve exclusivamente para la importacion de inventario al Sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
            }).then((result) => {
                if (result.value) {
                    this.spinnerloading = true
					this.plantillaImportacionExcel()
                    .then(response => {
                        if (response.status === 200) {
                            window.location.href = this.plantillaImportacion.link
                            this.spinnerloading = false
                        }
                    })
                    .catch(error => {
                        utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                    })
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'No Realizado',
                        'No has realizado ninguno :(',
                        'error'
                    )
                }
            })
            this.buttonKeyPlantillaImportacionExcel++
        },

        successFileImportacion (value) {
            this.file.importacionExcel = value
        },

		guardarImportacionExcel () {
			Swal.fire({
                title: 'Desea Importar Datos al Sistema ?',
                text: 'Esta opción guardara todos los datos existentes en el sistema y los reemplazara por la nueva Importación, Atención!!! Esta opción se recomienda utilizar la primera vez del uso del sistema.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Proceder Ahora',
                cancelButtonClass: 'btn btn-danger',
                confirmButtonClass: 'btn btn-success',
                cancelButtonText: 'No, Proceder!'
            }).then((result) => {
                if (result.value) {
					this.spinnerloading = true
					this.importacionExcel({ ...this.file, ...this.consulta, ...this.user })
                    .then(response => {
                        if (response.status === 201) {
                            this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
							this.getListaScrollMasivoProducto(this.consulta)
							this.getListaScrollMasivoProductoStock(this.consulta)
							setTimeout(function () { 
								this.modalimportacion = false       
								this.spinnerloading = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', response.data.message, 'topRight')
							}.bind(this), 5000)
                        }
                    })
                    .catch(error => {
                        utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                    })
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'No Realizado',
                        'No has realizado ninguno :(',
                        'error'
                    )
                }
            })
            this.buttonKeyImportacionExcel++
		},

        dataSuccessProducto () {
            
        }
	}
}
</script>

<style scoped>
.card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #000000;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

.option-description {
  color: #555;
  font-size: 1.1em;
}

ul {
  padding-left: 0px;
}

li {
  margin-bottom: 10px;
}

.note-title {
  font-weight: bold;
  color: #333;
}

.ant-alert-with-description .ant-alert-description {
    display: block;
    padding: 1px 1px 1px 1px !important;
}
</style>