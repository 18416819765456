<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-col flex="1 1 200px">
                <a-timeline>
                    <span style="color:black; font-size:18px;">Ingresos</span><br><br>

                    <a-timeline-item v-if="$can('transaccion.menu.nueva.venta')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                             @click="openMenuQuery('venta', 1)"
                            >
                            Nueva Venta
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('estimacion.menu.nueva.cotizacion')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenuQueryCotizacion('nuevacotizacion', 1)"
                            >
                            Nueva Cotizacion
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('arqueocaja')"
                            >
                            Nuevo Arqueo de Caja
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('arqueocaja')"
                            >
                            Nuevo Ingreso a Caja
                        </a-button>
                    </a-timeline-item>
                </a-timeline>

                <a-timeline>
                    <span style="color:black; font-size:18px;">Gastos</span><br><br>

                    <a-timeline-item v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('arqueocaja')"
                            >
                            Nuevo Gasto de Caja
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('adquisicion.menu.nueva.compra')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenuQuery('compra', 1)"
                            >
                            Nueva Compra
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('adquisicion.menu.nueva.compra')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="primary" 
                            size="small"
                            @click="openMenuQuery('comprapos', 1)"
                            >
                            Nueva Compra POS
                        </a-button>
                    </a-timeline-item>
                </a-timeline>
            </a-col>

            <a-col flex="1 1 200px">
                <a-timeline>
                    <span style="color:black; font-size:18px;">Inventarios</span><br><br>

                    <a-timeline-item v-if="$can('registro.menu.producto')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('catalogo')"
                            >
                            Nuevo Producto
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('desplazamiento.menu.nuevo.traspaso')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('transpaso')"
                            >
                            Nuevo Traspaso
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('correccion.menu.ajuste')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('ajusteinventariosalida')"
                            >
                            Nuevo Ajuste de Salida
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('correccion.menu.ajuste')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('ajusteinventarioentrada')"
                            >
                            Nuevo Ajuste de Entrada
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('agencia.menu.sucursal')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('sucursal')"
                            >
                            Nueva Sucursal
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('sistema.gestion.de.categoria')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button type="link" size="small">
                            Nuevo Lista de Venta
                        </a-button>
                    </a-timeline-item>
                </a-timeline>
			</a-col>

            <a-col flex="1 1 200px">
                <a-timeline>
                    <span style="color:black; font-size:18px;">Contactos</span><br><br>

                    <a-timeline-item v-if="$can('abastecedor.menu.proveedor')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('proveedor')"
                            >
                            Nuevo Proveedor
                        </a-button>
                    </a-timeline-item>

                    <a-timeline-item v-if="$can('consumidor.menu.cliente')">
                        <a-icon slot="dot" type="plus-circle-o" style="font-size: 16px;" />
                        <a-button 
                            type="link" 
                            size="small"
                            @click="openMenu('cliente')"
                            >
                            Nuevo Cliente
                        </a-button>
                    </a-timeline-item>
                </a-timeline>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

    export default {

        data () {
            return {
                
            }
        }, 

        computed: {
            ...mapGetters('configuracion', ['config'])
        },

        methods: {
            openMenu (name) {
				router.push({ name: name })
                this.$emit('successDrawer', 'realizado')
			},

			openMenuQuery (name, query) {
				this.$router.push({
					name: name,
					params: { ventanaID: query }
				})
                this.$emit('successDrawer', 'realizado')
			},

			openMenuQueryCotizacion (name, query) {
				this.$router.push({
					name: name,
					params: { ventanacotizacionID: query }
				})
                this.$emit('successDrawer', 'realizado')
			}
        }
    }
</script>