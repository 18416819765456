<template>
  	<div>
		<a-layout-header :style="headerStyle">
			<div class="header-content">
				<a-icon 
					class="trigger"
					:type="collapsed ? 'menu' : 'menu'"
					@click="toggleCollapsed"
					:style="{ fontWeight: '700', color: 'var(--element)', fontSize: '30px', marginLeft: '8px' }"
				/>
				<div class="button-group">
					<span class="hidden-app-x" style="font-size:16px;" v-if="this.config.enablemeasuredspeed === 'ACTIVADO'">
						<a-icon type="wifi" :style="{ color: 'var(--element)', fontWeight: '700' }"/>&nbsp;
						<span class="color-titulo-velocidad">{{ velocidad_a }}</span>
						<span v-if="this.status_velocidad == 1" style="color:#dc1602; font-weight:700;"> Conexión Critica</span>
						<span v-if="this.status_velocidad == 2" style="color:#d67b00; font-weight:700;"> Conexión Lenta</span>
						<span v-if="this.status_velocidad == 3" style="color:#0265dc; font-weight:700;"> Conexión Moderada</span>
						<span v-if="this.status_velocidad == 4" style="color:#47b703; font-weight:700;"> Conexión Estable</span>
					</span>

					<a-button
						v-if="$can('transaccion.menu.nueva.venta')"
						ref="ventaButton" 
						type="dashed" 
						class="hidden-app-x ant-btn-header v-step-escritorio-0" 
						style="margin-left: 4px;" 
						@click="openMenuQuery('venta', 1)"
						> 
						<a-icon type="shopping-cart" :style="{ fontSize: '20px', fontWeight: '700' }"/> Nueva Venta
					</a-button>

					<a-button 
						v-if="$can('adquisicion.menu.nueva.compra')"
						type="dashed" 
						class="hidden-app-x ant-btn-header v-step-escritorio-1" 
						style="margin-left: 2px;"
						@click="openMenuQuery('compra', 1)"
						> 
						<a-icon type="shopping" :style="{ fontSize: '20px', fontWeight: '700' }"/> Nueva Compra
					</a-button>

					<a-button 
						v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'"
						type="dashed" 
						class="hidden-app-x ant-btn-header v-step-escritorio-2" 
						style="margin-left: 2px;" 
						@click="openMenu('arqueocaja')"
						>
						<a-icon type="audit" :style="{ fontSize: '20px', fontWeight: '700' }"/> Caja
					</a-button>

					<a-button
						v-if="$can('existencia.menu.inventario')"
						type="dashed" 
						class="hidden-app-x ant-btn-header v-step-escritorio-3" 
						style="margin-left: 2px;" 
						@click="openMenu('inventario')"
						>
						<a-icon type="file-protect" :style="{ fontSize: '20px', fontWeight: '700' }"/> Inventario
					</a-button>

					<a-button 
						v-if="$can('registro.menu.producto')"
						type="primary" 
						class="hidden-app-x ant-btn-header v-step-escritorio-5" 
						style="margin-left: 2px;" 
						@click="openMenu('catalogo')"
						> 
						<a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> Producto
					</a-button>

					<a-button 
						type="link" 
						class="hidden-app-x"
						style="margin-top: 2px;" 
						@click.prevent="$tours['tour-escritorio'].start()" 
						> 
						<a-icon type="question-circle" :style="{ color: 'var(--element)', fontWeight: '700', fontSize: '28px' }"/>
					</a-button>

					<a-badge 
						:count="0"
						class="v-step-escritorio-6"
						style="margin-top: 2px;"
						>
						<a-icon type="bell" :style="{ color: 'var(--element)', fontWeight: '700', fontSize: '28px' }"/>
					</a-badge>

					<a-button 
						type="link" 
						style="margin-top: 2px;"
						class="v-step-escritorio-7" 
						@click="openDrawerMenu" 
						> 
						<a-icon type="appstore" :style="{ color: 'var(--element)', fontWeight: '700', fontSize: '28px' }"/>
					</a-button>		
					
					<div class="widget">
						<div class="reloj">
							<p id="horas" class="horas"></p>
							<p>:</p>
							<p id="minutos" class="minutos"></p>
							<p>:</p>
							<div class="cajaSegundos">
							<p id="ampm" class="ampm"></p>
							<p id="segundos" class="segundos"></p>
							</div>
						</div>
					</div>

					<a-button 
						type="link" 
						style="margin-top: 2px;" 
						@click="cerrarSesion()"
						> 
						<a-icon type="poweroff" :style="{ color: 'var(--element)', fontWeight: '700', fontSize: '28px' }"/>
					</a-button>	
				</div>
			</div>
		</a-layout-header>

		<a-modal 
            :visible="modalEmpresa"
            :footer="null"
            :width="420"
            :zIndex="1051"
            :dialog-style="{ top: '5px' }"
            @cancel="modalEmpresa = false"
			:closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
        >	
			<div class="row mb-12">
            	<CrearEmpresa />
			</div>
        </a-modal>

		<a-drawer
            title="Realizar Nueva Transaccion"
            placement="right"
            :closable="false"
            :mask="true"
            :maskClosable="true"
            :destroyOnClose="true"
            :visible="visibleDrawer"
            :zIndex="1030"
            :width="240"
            @close="onClose"
            >
            <Menu 
				@successDrawer="successDrawer"
			/>
        </a-drawer>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'
	import CrearEmpresa from '../pages/Empresa/CrearEmpresa.vue'
	import Menu from './Menu.vue'

	const listaActualizacion = [
		{ id: '1', name: 'Se mejoro el Nucleo del Sistema' },
		{ id: '2', name: 'Se implemento la independencia del Sistema' }
	]

	export default {

		props: [
			'velocidad_a', 
			'status_velocidad',
			'collapsed'
		],

		components: {
			CrearEmpresa,
			Menu
        },

		data () {
			return {
				visibleDrawer: false,
				listaActualizacion,
				modalEmpresa: false
			}
		},

		mounted () {
			this.iniciarReloj()
		},

		computed: {
			...mapGetters('configuracion', ['config']),
			...mapGetters('auth', ['user']),
			
			headerStyle () {
				return {
					background: '#fff',
					padding: 0,
					position: 'fixed',
					width: this.collapsed ? '100%' : 'calc(100% - 160px)',
					zIndex: 1000,
					left: this.collapsed ? '0' : '160px',
					transition: 'width 0.2s, left 0.2s',
					boxShadow: 'inset 0 -3px 5px -3px rgba(0, 0, 0, 0.2)'
				}
			}
		},

		methods: {
			...mapActions('auth', ['logout']),

			iniciarReloj () {
				const actualizarHora = () => {
					const fecha = new Date()
					let hora = fecha.getHours()
					const minutos = fecha.getMinutes()
					const segundos = fecha.getSeconds()
					let ampm

					const pHoras = document.getElementById('horas')
					const pSegundos = document.getElementById('segundos')
					const pMinutos = document.getElementById('minutos')
					const pAMPM = document.getElementById('ampm')

					// Ajusta la hora y el formato AM/PM
					if (hora >= 12) {
						ampm = 'PM'
						if (hora > 12) hora -= 12
					} else {
						ampm = 'AM'
						if (hora === 0) hora = 12
					}

					// Actualiza el contenido de los elementos
					if (pHoras && pMinutos && pSegundos && pAMPM) {
						pHoras.textContent = hora < 10 ? '0' + hora : hora
						pMinutos.textContent = minutos < 10 ? '0' + minutos : minutos
						pSegundos.textContent = segundos < 10 ? '0' + segundos : segundos
						pAMPM.textContent = ampm
					}
				}

				// Llama a actualizarHora inmediatamente y luego cada segundo
				actualizarHora()
				setInterval(actualizarHora, 1000)
			},

			toggleCollapsed () {
				this.$emit('toggleCollapsed')
			},

			onClose () {
                this.visibleDrawer = false
            },

			openDrawerMenu () {
                this.visibleDrawer = true
            },

			successDrawer () {
				setTimeout(() => {
					this.visibleDrawer = false
				}, 500)
			},

			openModalEmpresa () {
				this.modalEmpresa = true
			},

			openMenu (name) {
				router.push({ name: name })
			},

			openMenuQuery (name, query) {
				this.$router.push({
					name: name,
					params: { ventanaID: query }
				})
			},

			irPagina (ruta) {
				this.$router.push({ path: ruta })
			},

			cerrarSesion () {
				this.logout().then(response => {
					utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
				}).catch(error => {
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
			}
		}
	}
</script>

<style>

.ant-layout-header {
    height: 50px;
    padding: 0 50px;
    line-height: 50px;
    background: var(--element);
}

/* Asegúrate de ajustar el contenedor del reloj dentro del header */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Asegura que el reloj ocupe el alto del header */
  padding-right: 2px;
}

.reloj {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color:#fff;
  width: auto; /* Ajustar el ancho automáticamente */
  padding: 0px;
  font-size: 1.7em; /* Ajuste el tamaño de fuente según sea necesario */
  font-weight: 700;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100%; /* Ocupa todo el alto del header */
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
}

.reloj p {
  margin: 0;
  display: inline-block;
  line-height: 1em;
}

.reloj .cajaSegundos {
  display: inline-block;
  margin-left: 1px;
}

.reloj .ampm {
  display: block;
  font-size: 0.8em; /* Ajusta el tamaño del texto si es necesario */
}

.reloj .segundos {
  display: block;
  font-size: 0.8em; /* Ajusta el tamaño del texto si es necesario */
}

.header-content .widget {
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.ant-btn-header {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 35px;
    padding: 0 6px;
    font-size: 14px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
}

.header-notifications-list {
		background: #ffffff;
		border-radius: 12px;
		min-width: 176px;
		padding: 16px 8px;

		ul.ant-dropdown-menu-items {
			padding: 0;
			margin: 0;
		}
	
		.ant-list-item {
			padding: 5px 16px;
			margin-bottom: 8px;
	
			&:last-child {
				margin-bottom: 0;
			}
		}
		.ant-list-item-meta-title {
			line-height: normal;
		}
	
		.ant-avatar {
			width: 36px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: 22px;
				height: 22px;
			}
		}
		.ant-list-item-meta-description {
			font-size: 12px;
			line-height: 12px;
			span {
				vertical-align: middle;
			}
		}
	}
</style>