<template>
    <div>
		<a-row type="flex">
            <a-col flex="1 1 200px" :span="6"></a-col>
			<a-col flex="1 1 500px" :span="12">
				<a-collapse v-model="activeKey" :expand-icon-position="`right`" :bordered="false">
					<a-collapse-panel v-for="(item, index) in empresas" :key="index">
						<span slot="header">
                            <a-avatar :size="50" src="/static/assets/images/icono.png" />
                            <a-divider type="vertical" />
                            <span style="font-size:20px;"> {{ item.nombre.toUpperCase() }}</span>
                        </span>
                        
                        <a-list item-layout="horizontal" :data-source="item.almacenes">
                            <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                                <a-list-item-meta>
                                    <span slot="title" style="font-size:18px;">
                                        <a-icon type="safety-certificate" :style="{ fontSize: '18px', color: 'green', fontWeight: '700' }"/> {{ item.nombre }}
                                    </span>
                                </a-list-item-meta>
                                <a slot="actions">
                                    <a-button type="primary"><a-icon type="eye" /> Ingresar </a-button>
                                </a>
                            </a-list-item>
                        </a-list>
					</a-collapse-panel>
				</a-collapse>

				<br><br>

				<a-button size="large" type="primary" block>
					<a-icon type="plus" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> Agregar nueva empresa
				</a-button>
			</a-col>
			<a-col flex="1 1 200px" :span="6"></a-col>
		</a-row>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'

  export default {

      data () {
          return {
			activeKey: ['0', '1', '2']
          }
      }, 

	  watch: {
		  activeKey (key) {
			console.log(key)
		  }
	  },

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('empresa', ['empresas'])
      },

      methods: {
          ...mapActions('auth', ['logout']),
 
          handleClick (event) {
              event.stopPropagation()	
          }
      }
  }
</script>