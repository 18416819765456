<template>
	<div>
		<template v-if="user">
			<template v-if="user.tenant.estado_configuracion === 'TERMINADO'">
				<TourSystem /> 

				<a-layout id="components-layout-demo-custom-trigger" :class="{'layout-collapsed': collapsed}">
					<Sidebar
						:velocidad_a="velocidad_a" 
						:status_velocidad="status_velocidad" 
						:collapsed="collapsed"
						:sidebarStyle="sidebarStyle"
						@breakpoint="handleBreakpoint"
						@menuClick="handleMenuClick"
						@sidebarClick="handleSidebarClick"
					/>
					<a-layout>
						<Header
							:velocidad_a="velocidad_a" 
							:status_velocidad="status_velocidad" 
							:collapsed="collapsed"
							@toggleCollapsed="toggleCollapsed"
						/>
						<a-layout-content
							:style="{ margin: '52px 1px 6px', padding: '3px', background: '#fff', minHeight: '100vh', marginLeft: collapsed ? '0' : '163px' }"
							>
							<router-view
								v-slot="{ Component }"
								:usuario="user"
								:config="config"
								>
								<transition>
									<keep-alive>
										<component :is="Component" />
									</keep-alive>
								</transition>
							</router-view>
						</a-layout-content>
						<a-layout-footer>
							<Footer />
						</a-layout-footer>
					</a-layout>
				</a-layout>
			</template>

			<template v-if="user.tenant.estado_configuracion === 'EN ESPERA'">
				<Inicializacion 
					@successRegistro="registrarProcesoConfiguracion"
				/>
			</template>
		</template>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: var(--element); z-index: 11000;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="efectivo.io"></vue-simple-spinner>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Header from './Header.vue'
	import Sidebar from './Sidebar.vue'
	import Footer from './Footer.vue'
	import Cookies from 'js-cookie'
	import PrettyRadio from 'pretty-checkbox-vue/radio'
	import PrettyCheck from 'pretty-checkbox-vue/check'
	import PrettyInput from 'pretty-checkbox-vue/input'

	import { mapActions, mapGetters } from 'vuex'
	import * as utils from '@/utils/utils'
	import { 
		TOKEN, 
		USER_ID,
		ALMACEN_SISTEMA_KEY_ID,
		SISTEMA_USUARIO
	} from '../../../utils/constants'
	import router from '@/config/router'
	import * as database from '@/utils/database'

	import TourSystem from './TourSystem.vue'
	import Inicializacion from './Configuracion/Inicializacion.vue'

	import { generateTabId, setupLeader, isLeader, handleChannelMessage } from '@/utils/setupLeader'

	Vue.component('p-radio', PrettyRadio)
	Vue.component('p-check', PrettyCheck)
	Vue.component('p-input', PrettyInput)

	document.addEventListener('keydown', function (e) {
    const key = e.keyCode || e.which
		if (
			(key === 27) ||
			(key === 123) ||
			((key === 85 || key === 82) && e.ctrlKey) ||
			(key === 37 && e.altKey)
		) {
			e.preventDefault()
		}
	})

	let isChromeWindowClosed = false

	document.addEventListener('visibilitychange', function () {
		if (document.visibilityState === 'hidden') {
			isChromeWindowClosed = true
		}
	})

	window.addEventListener('beforeunload', function (e) {
		if (isChromeWindowClosed) {
			// El usuario cerró la pestaña o ventana del navegador
			// Eliminar la Sesion cuando se cierra el navegador
			// Obtener el token guardado en la cookie
			const token = sessionStorage.getItem(TOKEN)
			// Si hay un token, eliminar la cookie
			if (!token) {
				console.log('cerrando session')
				Cookies.remove(USER_ID)
				Cookies.remove(TOKEN)
				Cookies.remove(ALMACEN_SISTEMA_KEY_ID)
				Cookies.remove(SISTEMA_USUARIO)
			}
		} else {
			// El usuario recargó la página o navegó a otra URL
			console.log('Página recargada o navegación')
		}
	})

	export default {

		components: {
			Inicializacion,
			Header,
			Sidebar,
			Footer,
			TourSystem
		},

		data () {
			return {
				velocidad_a: '',
				velocidad_b: '',
				status_velocidad: '',
				estadoArqueoCaja: '',
				spinnerloading: false,
				consulta: {
					almacen_id: ''
				},
				collapsed: window.innerWidth <= 768,
				sidebarStyle: {
					position: 'fixed',
					height: '100vh',
					left: 0,
					top: 0,
					bottom: 0,
					transition: 'all 0.2s',
					overflow: 'auto'
				},
				tabId: null,
				leaderInterval: null,
				channel: null
			}
		},

		computed: {
			...mapGetters('auth', ['user']),
			...mapGetters('configuracion', ['config'])
		},

		methods: {
			...mapActions('empresa', ['getEmpresas', 'showEmpresa']),
			...mapActions('auth', ['checkUserMain', 'reportarData']),
			...mapActions('configuracion', ['getConfiguraciones', 'registrarConfiguracionInicio']),
			...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),
			...mapActions('arqueocaja', ['controlArqueoCajaCerrada']),

            obtenerDatosEmpresa () {
				this.showEmpresa()
				.then(response => {
					setTimeout(function () {
						this.spinnerloading = false
					}.bind(this), 4000)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
			},

			ShowProgressMessage (msgA, msgB, msgC) {
				if (msgC === '') { 
					console.log(msgA)
				} else {
					this.velocidad_a = msgA
					this.velocidad_b = msgB

					setTimeout(function () {
						if (this.velocidad_b === 0) { 
							this.status_velocidad = 0
							// utils.openNotificationWithIcon('error', 'Atención !!!', 'No tienes conexión a internet.', 'topRight')
						} else if (this.velocidad_b === 0 || this.velocidad_b <= parseFloat(1)) {
							this.status_velocidad = 1
							// utils.openNotificationWithIcon('error', 'Atención !!!', 'No tienes sufiente velocidad de conexión a Internet, verifica tu Velocidad de Internet', 'topRight')
						} else if (this.velocidad_b <= parseFloat(2)) {
							this.status_velocidad = 2
							// utils.openNotificationWithIcon('warning', 'Atención !!!', 'La velocidad de conexión a internet es lenta.', 'topRight')
						} else if (this.velocidad_b <= parseFloat(3)) {
							this.status_velocidad = 3
							// utils.openNotificationWithIcon('info', 'Atención !!!', 'La velocidad de conexión a internet es moderado.', 'topRight')
						} else if (this.velocidad_b > parseFloat(3)) {
							this.status_velocidad = 4
						}
					}.bind(this), 100)
				}
			},

			InitiateSpeedDetection () {
				this.ShowProgressMessage('Verificando la conexion de Internet...', '', '')
				this.MeasureConnectionSpeed()
			},

			MeasureConnectionSpeed () {
				const self = this
				const imageAddr = 'https://images.freeimages.com/vhq/images/previews/d50/butterfly-papilio-philenor-side-clip-art-545705.jpg' 
				const downloadSize = 84000

				var startTime, endTime
				var download = new Image()
				download.onload = function () {
					const endTime = (new Date()).getTime()
					const duration = (endTime - startTime) / 1000
					const bitsLoaded = downloadSize * 8
					const speedBps = (bitsLoaded / duration).toFixed(2)
					const speedKbps = (speedBps / 1024).toFixed(2)
					const speedMbps = (speedKbps / 1024).toFixed(2)
					const speedMbpsInt = parseFloat((speedKbps / 1024).toFixed(2))

					const msg = [
						speedBps + ' bps', 
						speedKbps + ' kbps', 
						speedMbps + ' Mbps',
						speedMbpsInt
					]

					const msgA = speedMbps + ' Mbps'
					const msgB = speedMbpsInt

					self.ShowProgressMessage(msgA, msgB)
				}

				download.onerror = function (err, msg) {
					this.velocidad_b = 0
					this.status_velocidad = 0
					if (err) {
						// utils.openNotificationWithIcon('error', 'Atención !!!', 'No tienes conexión a internet, el sistema no funcionara en tu dispositivo.', 'topRight')
					}
					self.ShowProgressMessage('Error al comprobar tu conexión...', '', '')
				}
					
				startTime = (new Date()).getTime()
				const cacheBuster = '?nnn=' + startTime
				download.src = imageAddr + cacheBuster
			},

			detectedVelocityInternet () {
				this.InitiateSpeedDetection()
			},

			initCheckPermisos () {
				this.spinnerloading = true
				this.$ability.update([
					{ subject: 'all', action: {} }
				])
			},

			checkUsuario () {
				this.checkUserMain()
				.then(response => {
                    this.$ability.update([
						{ subject: 'all', action: this.user.permisos }
					])
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
			},

			verifiedUsuarioAndToToken () {
				const usuarioID = Cookies.get(USER_ID)
				const tokenCookie = Cookies.get(TOKEN)
				const tokenSession = sessionStorage.getItem(TOKEN)

				if (!usuarioID) {
					const mensaje = 'UsuarioID Cookie no existe: '
					const aviso = `${mensaje} ${new Date().toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`
					this.reportarData(aviso)
				}

				if (!tokenCookie) {
					const mensaje = 'Token Cookie no existe'
					const aviso = `${mensaje} ${new Date().toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`
					this.reportarData(aviso)
				}

				if (!tokenSession) {
					const mensaje = 'Token Session Storage no existe'
					const aviso = `${mensaje} ${new Date().toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`
					this.reportarData(aviso)
				}
			},

			toggleCollapsed () {
				this.collapsed = !this.collapsed
			},

			handleBreakpoint (broken) {
				if (broken) {
					this.collapsed = true
					this.sidebarStyle.position = 'relative'
				} else {
					this.collapsed = false
					this.sidebarStyle.position = 'fixed'
				}
			},

			handleMenuClick () {
				if (this.collapsed) {
					this.collapsed = false
				}
			},

			handleSidebarClick (event) {
				if (window.innerWidth <= 768 && !this.collapsed) {
					this.collapsed = true
				}
			},

			handleResize () {
				if (window.innerWidth <= 768) {
					this.collapsed = true
				} else {
					this.collapsed = false
				}
			},

			/** Manejador de una sola ventana */
			setupIntervals () {
				const intervalTimes = {
					controlArqueoCajaCerrada: this.getRandomInterval(40000, 120000),
					verifiedUsuarioAndToToken: this.getRandomInterval(55000, 120000),
					checkUsuario: this.getRandomInterval(50000, 120000),
					detectedVelocityInternet: this.getRandomInterval(8000, 20000)
				}

				// Intervalo para verifiedUsuarioAndToToken
				setInterval(() => {
					if (isLeader(this.tabId)) {
						this.verifiedUsuarioAndToToken()
					} else {
						console.log('No es la pestaña líder, no se realizará la solicitud para verifiedUsuarioAndToToken.')
					}
				}, intervalTimes.verifiedUsuarioAndToToken)

				// Intervalo para checkUsuario
				setInterval(() => {
					if (isLeader(this.tabId)) {
						this.checkUsuario()
					} else {
						console.log('No es la pestaña líder, no se realizará la solicitud para checkUsuario.')
					}
				}, intervalTimes.checkUsuario)

				setTimeout(() => {
					if (this.config.habilitararqueocaja === 'ACTIVADO') {
						setInterval(() => {
							if (isLeader(this.tabId)) {
								const almacenID = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
								this.controlArqueoCajaCerrada(almacenID)
							} else {
								console.log('No es la pestaña líder, no se realizará la solicitud para checkUsuario.')
							}
						}, intervalTimes.controlArqueoCajaCerrada)
					}
				}, 1000)

				// Intervalo para detectedVelocityInternet
				setTimeout(() => {
					if (this.config.enablemeasuredspeed === 'ACTIVADO') {
						this.detectedVelocityInternet()

						setInterval(() => {
							if (isLeader(this.tabId)) {
								this.detectedVelocityInternet()
							} else {
								console.log('No es la pestaña líder, no se realizará la solicitud para detectedVelocityInternet.')
							}
						}, intervalTimes.detectedVelocityInternet)
					}
				}, 1000)
			},

			handleChannelMessage (event) {
				handleChannelMessage(event)
			},

			getRandomInterval (min, max) {
				return Math.floor(Math.random() * (max - min + 1)) + min
			},

			registrarProcesoConfiguracion (value) {
				this.spinnerloading = true
				this.registrarConfiguracionInicio()
				.then(response => {
					this.spinnerloading = false
					this.$message.success('El proceso de configuración ha sido registrado!')
                    setTimeout(() => {
						router.go()
					}, 1000)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
			},

			getSistemaProduccion () {
				this.getEmpresas()
				this.getConfiguraciones()
				this.obtenerDatosEmpresa()

				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
				this.getListaScrollMasivoProducto(this.consulta)
				this.getListaScrollMasivoProductoStock(this.consulta)

				this.tabId = generateTabId()
				const { channel, leaderInterval } = setupLeader(this.tabId, this.handleChannelMessage)
				this.channel = channel
				this.leaderInterval = leaderInterval
				this.setupIntervals()
			}
		},

		beforeCreate () {
			document.body.removeAttribute('class')

			window.addEventListener('resize', this.handleResize)
			const color = (localStorage.getItem('colorBaseSistema')) ? localStorage.getItem('colorBaseSistema') : '#014694'
			document.documentElement.style.setProperty('--element', color)
		},

		beforeDestroy () {
			window.removeEventListener('resize', this.handleResize)
		},

		async created () {
			this.spinnerloading = true

			this.initCheckPermisos()
			this.checkUsuario()

			database.deleteDatabase()
    		.then(() => console.log('Base de datos eliminada con éxito'))
    		.catch((error) => console.error('Error al eliminar la base de datos:', error))
		},

		async mounted () {
			this.getSistemaProduccion()
		}
	}
</script>

<style scoped>
	@import '../../../../public/static/assets/css/skins/skin-purple-light.min.css';

	#components-layout-demo-custom-trigger .ant-layout {
		transition: margin-left 0.2s;
	}
	.ant-input-number-lg {
		padding: 0;
		font-size: 22px;
	}
	.layout-collapsed .ant-layout-content {
		margin-left: 0;
		transition: margin-left 0.2s;
	}
	.layout-collapsed .ant-layout-header {
		width: 100%;
		transition: width 0.2s;
	}
</style>
