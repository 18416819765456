<template>
    <a-card class="step-card">
        <a-steps :current="current">
            <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
        <div class="steps-content">
            <!-- Renderizar componentes de formularios según el paso actual -->
            <component :is="steps[current].component"></component>
        </div>
        <div class="steps-action">
            <a-button 
				v-if="current > 0" 
				style="margin-left: 8px" 
				@click="prev"
				size="large"
				>
                <a-icon type="left" /> Paso Anterior
            </a-button>
			
			<a-button 
				v-if="current < steps.length - 1" 
				@click="next"
				size="large"
				>
                <a-icon type="right" /> Siguiente Paso
            </a-button>

            <a-button
                v-if="current === steps.length - 1"
                type="primary"
                @click="enviarRegistro()"
				size="large"
            >
                Finalizar Configuracion
            </a-button>
        </div>
    </a-card>
</template>

<script>
import EmpresaForm from './components/EmpresaForm.vue'
import SistemaForm from '../../pages/Parametro/Modulo/Configuracion.vue'
import ParametrosForm from '../../pages/Parametro/Modulo/Parametrizacion.vue'
import ProductosForm from './components/ProductoForm.vue'

export default {
    data () {
        return {
            current: 0,
            steps: [
                {
                    title: 'Configuración de Empresa',
                    component: EmpresaForm
                },
                {
                    title: 'Configuración de Parámetros',
                    component: ParametrosForm
                },
                {
                    title: 'Agregar Nuevos Productos',
                    component: ProductosForm
                }
            ]
        }
    },
    methods: {
        next () {
            this.current++
        },
        
        prev () {
            this.current--
        },
        
        enviarRegistro () {
            this.$emit('successRegistro', 'Realizado con Exito')
        }
    }
}
</script>

<style scoped>
.step-card {
    max-width: 1100px;
    margin: 20px auto 0 auto;
    padding: 24px;
}

.steps-content {
    margin-top: 16px;
    padding: 16px;
    background-color: #fafafa;
    border-radius: 6px;
    border: 1px dashed #e9e9e9;
}

.steps-action {
    margin-top: 24px;
    text-align: center;
}

/* Media Queries para hacer el diseño responsivo */
@media (max-width: 768px) {
    .step-card {
        max-width: 90%;
        padding: 16px;
    }

    .steps-content {
        padding: 12px;
    }

    .steps-action a-button {
        width: 100%;
        margin-bottom: 8px;
    }
}

@media (max-width: 480px) {
    .step-card {
        max-width: 100%;
        padding: 12px;
    }

    .steps-content {
        padding: 8px;
    }

    .steps-action a-button {
        width: 100%;
        margin-bottom: 8px;
    }
}
</style>