<template>
    <div>
        <v-tour name="tour-escritorio" :steps="steps" :callbacks="callbacks" :options="myOptions"></v-tour>
    </div>
</template>
  
<script>
    export default {
        data () {
            return {
                myOptions: {
                    useKeyboardNavigation: true,
                    labels: {
                        buttonSkip: 'Cerrar',
                        buttonPrevious: 'Anterior',
                        buttonNext: 'Siguiente',
                        buttonStop: 'Finalizar'
                    }
                },
                callbacks: {
                    onPreviousStep: this.myCustomPreviousStepCallback,
                    onNextStep: this.myCustomNextStepCallback
                },
                steps: [
                    {
                        target: '.v-step-escritorio-0',
                        header: {
                            title: 'Nueva Venta'
                        },
                        content: 'Permite crear una <strong>nueva venta</strong> de manera rápida y sencilla, registrando los productos vendidos y generando el recibo correspondiente para el cliente.',
                        params: {
                            placement: 'bottom',
                            highlight: true
                        }
                    },
                    {
                        target: '.v-step-escritorio-1',
                        header: {
                            title: 'Nueva Compra'
                        },
                        content: 'Facilita el registro de <strong>nuevas compras</strong>, añadiendo productos al inventario y gestionando detalles de los proveedores, para mantener actualizado el stock.',
                        params: {
                            placement: 'bottom',
                            highlight: true
                        }
                    },
                    {
                        target: '.v-step-escritorio-2',
                        header: {
                            title: 'Nueva Arqueo de Caja'
                        },
                        content: 'Realiza un <strong>arqueo de caja</strong> para verificar el efectivo disponible en caja, registrando y gestionando cualquier discrepancia encontrada en el proceso.',
                        params: {
                            placement: 'bottom',
                            highlight: true
                        }
                    },
                    {
                        target: '.v-step-escritorio-3',
                        header: {
                            title: 'Inventarios'
                        },
                        content: 'Visualiza y gestiona los <strong>niveles de inventario</strong>, permitiendo actualizar cantidades, ubicaciones y detalles de los productos de manera eficiente.',
                        params: {
                            placement: 'bottom',
                            highlight: true
                        }
                    }
                ]
            }
        },

        methods: {
            myCustomPreviousStepCallback (currentStep) {
                const data = `.v-step-escritorio-${currentStep}`
                document.querySelector(data).scrollIntoView({ behavior: 'smooth' })
            },

            myCustomNextStepCallback (currentStep) {
                const data = `.v-step-escritorio-${currentStep}`
                document.querySelector(data).scrollIntoView({ behavior: 'smooth' })
                document.querySelector('.v-step').classList.add('no-focus')
            }
        }
    }
</script>

<style>
    .v-tour__target--highlighted {
        box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.404);
        pointer-events: none;
        z-index: 9999;
    }

    .v-step {
        background: #212020 !important;
        color: #fff;
        max-width: 320px;
        border-radius: 3px;
        -webkit-box-shadow: transparent 0 0 0 0, transparent 0 0 0 0, rgba(0, 0, 0, .1) 0 4px 6px -1px, rgba(0, 0, 0, .06) 0 2px 4px -1px;
        box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
        padding: 1rem;
        pointer-events: auto;
        text-align: center;
        font-size: 18px;
        z-index: 10000 !important;
        outline: none;
    }

    .v-step__button {
        background: transparent;
        border: .05rem solid #fff;
        border-radius: .1rem;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem !important;
        height: 2rem !important;
        line-height: 1rem;
        outline: none;
        margin: 0 .2rem;
        padding: .35rem .4rem;
        text-align: center;
        text-decoration: none;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        vertical-align: middle;
        white-space: nowrap;
        outline: none;
    }
</style>