export function generateTabId () {
    return Math.random().toString(36).substr(2, 9)
}

export function setupLeader (tabId, handleChannelMessage) {
    const leaderKey = 'leader_key'
    const leaderCheckInterval = 5000 // Intervalo de comprobación del líder en milisegundos

    // Usar BroadcastChannel para coordinar entre pestañas
    const channel = new BroadcastChannel('leader_channel')
    channel.onmessage = handleChannelMessage

    const tryToBecomeLeader = () => {
        const currentLeader = localStorage.getItem(leaderKey)
        // console.log(`Intentando convertirse en líder. tabId: ${tabId}, currentLeader: ${currentLeader}`)

        if (!currentLeader) {
            localStorage.setItem(leaderKey, tabId)
            channel.postMessage({ type: 'SET_LEADER', tabId })
            // console.log('Esta pestaña es la nueva líder.')
        } else if (currentLeader === tabId) {
            // Si el líder actual es esta pestaña, mantener el liderazgo
            // console.log('Esta pestaña ya es la líder.')
        } else {
            // console.log('No es la pestaña líder.')
        }
    }

    // Intentar convertirse en líder al cargar la pestaña
    tryToBecomeLeader()

    // Comprobar periódicamente si esta pestaña sigue siendo líder
    const leaderInterval = setInterval(tryToBecomeLeader, leaderCheckInterval)

    // Limpiar el liderazgo cuando la pestaña se cierre
    window.addEventListener('beforeunload', () => {
        if (localStorage.getItem(leaderKey) === tabId) {
            localStorage.removeItem(leaderKey)
            channel.postMessage({ type: 'REMOVE_LEADER' })
            // console.log('Esta pestaña ha dejado de ser la líder.')
        }
        clearInterval(leaderInterval)
        channel.close()
    })

    return { channel, leaderInterval }
}

export function isLeader (tabId) {
    const leaderKey = 'leader_key'
    const leader = localStorage.getItem(leaderKey)
    // console.log(`Verificando liderazgo. tabId: ${tabId}, leader: ${leader}`)
    return leader === tabId
}

export function handleChannelMessage (event) {
    const { type, tabId } = event.data
    const leaderKey = 'leader_key'

    if (type === 'SET_LEADER') {
        localStorage.setItem(leaderKey, tabId)
        // console.log('Líder actualizado a pestaña:', tabId)
    } else if (type === 'REMOVE_LEADER') {
        localStorage.removeItem(leaderKey)
        // console.log('Líder eliminado.')
    }
}